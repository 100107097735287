body#top {
  position: relative;
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
}
@media screen and (max-width: 767px) {
  body#top {
    background-size: auto 205vh;
  }
}

.top-ttl {
  margin: 0 0 40px;
  /* color: $primary; */
  color: #00a7b5;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .top-ttl {
    margin: 0 0 30px;
  }
}
.top-ttl span {
  display: block;
}
.top-ttl span[lang=en] {
  font-size: 30px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .top-ttl span[lang=en] {
    font-size: 23px;
  }
}
.top-ttl span[lang=ja] {
  margin: 5px 0 0;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .top-ttl span[lang=ja] {
    font-size: 11px;
  }
}

.top-kv {
  position: relative;
  padding: 0 50px;
  /* background: $primary; */
  /* background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto; */
}
@media screen and (max-width: 767px) {
  .top-kv {
    padding: 0 20px;
    background-size: cover;
  }
}
.top-kv-inner {
  display: flex;
  align-items: center;
  position: relative;
  aspect-ratio: 1398.9705/679.569;
  /* height: calc(100vh - 122px); */
  padding: 0 6.67vw;
  background: url(/assets/img/top/kv-03.jpg) no-repeat 50% 50%;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .top-kv-inner {
    height: calc(100vh - 65px);
    aspect-ratio: inherit;
    padding: 0 7.2vw;
    background-position: top 0 right 30%;
    background-size: auto 100%;
  }
}
.top-kv-inner img {
  width: 83vw;
  aspect-ratio: 1249.8457/118.6195;
  margin-inline: auto;
}
.top-kv-scroll {
  position: absolute;
  bottom: 0;
  left: 50%;
  color: #fff;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  letter-spacing: 0.1em;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .top-kv-scroll {
    font-size: 10px;
  }
}
.top-kv-scroll:after {
  content: "";
  display: block;
  width: 1px;
  height: 130px;
  margin: 5px auto -65px;
  background: #fff;
  animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;
  z-index: 1000;
}
@media screen and (max-width: 767px) {
  .top-kv-scroll:after {
    height: 58px;
    margin-bottom: -29px;
  }
}
.top-kv-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: calc(100vh - 122px);
  color: #fff;
  font-size: 10px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  letter-spacing: 0.1em;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
@media screen and (max-width: 767px) {
  .top-kv-txt {
    width: 20px;
    height: calc(100vh - 65px);
  }
}

@keyframes sdl {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
.top-intro {
  padding: 120px 20px 200px;
  color: #fff;
  text-align: center;
  /* background: $primary; */
  /* background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto; */
}
@media screen and (max-width: 767px) {
  .top-intro {
    padding: 70px 20px 100px;
  }
}
.top-intro-ttl {
  margin: 0 0 50px;
  color: #00a7b5;
  font-size: 30px;
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  .top-intro-ttl {
    font-size: 18px;
  }
}
.top-intro-txt {
  color: #000;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .top-intro-txt {
    font-size: inherit;
    text-align: left;
  }
}

.top-slide {
  position: relative;
  line-height: 1;
  margin: -75px 0 0;
}
@media screen and (max-width: 767px) {
  .top-slide {
    margin: -60px 0 40px;
  }
}
.top-slide-inner {
  width: 940px;
  overflow: hidden;
  transform: translateX(126px);
}
@media screen and (max-width: 767px) {
  .top-slide-inner {
    width: calc(100vw - 20px);
    transform: none;
  }
}
.top-slide-inner .slick-arrow {
  display: block;
  position: absolute;
  bottom: 136px;
  overflow: hidden;
  width: 12px;
  height: 12px;
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #656565;
  border-right: 1px solid #656565;
  border-radius: 0;
  text-indent: -100vw;
  background: none;
  cursor: pointer;
  z-index: 2000;
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-arrow {
    width: 10px;
    height: 10px;
    bottom: 70px;
  }
}
.top-slide-inner .slick-arrow.slick-prev {
  right: 148px;
  transform: rotate(-135deg);
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-arrow.slick-prev {
    right: 35vw;
  }
}
.top-slide-inner .slick-arrow.slick-next {
  right: 128px;
  transform: rotate(45deg);
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-arrow.slick-next {
    right: 30vw;
  }
}
.top-slide-inner .slick-dots {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  list-style: none;
  height: 95px;
  margin: 0;
  padding: 0 120px 0 0;
  background: #fff;
  transform: translate(-126px, -100%);
  z-index: 1000;
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-dots {
    justify-content: flex-start;
    width: 60vw;
    height: 50px;
    padding: 0 0 0 25px;
    transform: translate(0, -100%);
  }
}
.top-slide-inner .slick-dots:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -50px;
  bottom: 0;
  width: 50px;
  background: #fff;
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-dots:after {
    content: none;
  }
}
.top-slide-inner .slick-dots li button {
  display: block;
  width: 50px;
  height: 2px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: none;
  text-indent: -100vw;
  background: #c2c2c2;
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-dots li button {
    width: 20px;
  }
}
.top-slide-inner .slick-dots li.slick-active button {
  background: #656565;
}
.top-slide-inner .slick-num {
  position: absolute;
  bottom: 135px;
  left: 360px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  z-index: 1000;
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-num {
    bottom: 70px;
    left: 0;
  }
}
.top-slide-inner .slick-all-count {
  position: absolute;
  right: 210px;
  bottom: 135px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  z-index: 1000;
}
@media screen and (max-width: 767px) {
  .top-slide-inner .slick-all-count {
    right: auto;
    bottom: 70px;
    left: 155px;
  }
}

.top-career {
  width: 100vw;
  margin: 0 calc(-50vw + 50%) 200px;
  padding: 30px 0;
  background-image: url(../img/top/career-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .top-career {
    margin: 0 calc(-50vw + 50%) 100px;
  }
}
.top-career__inner {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (max-width: 767px) {
  .top-career__inner {
    width: calc(100% - 20px - 20px);
  }
}
.top-career__ul {
  display: flex;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translateY(20%);
}
@media screen and (max-width: 767px) {
  .top-career__ul {
    flex-direction: column;
    align-items: center;
    transform: none;
  }
}
.top-career__li {
  width: calc((100% - 8px) / 2);
  padding: min(60px, 4vw) min(80px, 5.3vw);
  background-color: #00a7b5;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .top-career__li {
    width: calc(100% - 20px - 20px);
    margin-inline: auto;
    padding: 10px 20px 20px;
  }
}
.top-career__title--main {
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 767px) {
  .top-career__title--main {
    font-size: 28px;
  }
}
.top-career__title--en {
  display: block;
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .top-career__title--en {
    font-size: 12px;
  }
}
.top-career__desc {
  font-size: 13px;
}
.top-career__link {
  width: 50px;
  aspect-ratio: 1;
  opacity: 1;
  transition: opacity 0.3s;
}
.top-career__link img {
  width: 50px;
  aspect-ratio: 1;
}
.top-career__link:hover {
  opacity: 0.7;
}

.top-news {
  position: relative;
  margin: 0 0 160px;
}
@media screen and (max-width: 767px) {
  .top-news {
    margin: 0 0 80px;
  }
}
.top-news-btn {
  position: absolute;
  top: 15px;
  right: 0;
  margin: 0;
}
.top-news-btn a {
  color: #00a7b5;
}
.top-news-list {
  height: 450px;
  overflow: auto;
  padding: 0 30px 0 0;
}
@media screen and (max-width: 767px) {
  .top-news-list {
    padding: 0 20px 0 0;
  }
}
.top-news-list::-webkit-scrollbar {
  width: 4px;
}
.top-news-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #515151;
}
.top-news-list-item {
  margin: 0 0 -1px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.top-news-list-item a {
  display: block;
  padding: 20px 0;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .top-news-list-item a:hover .top-news-list-ttl {
    text-decoration: underline;
  }
}
.top-news-list-item > span {
  display: block;
  padding: 20px 0;
}
.top-news-list-data {
  display: flex;
  line-height: 1;
  color: #00a7b5;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
}
.top-news-list-date {
  padding: 5px 0 0;
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  .top-news-list-date {
    font-size: 10px;
  }
}
.top-news-list-cat {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 20px;
}
.top-news-list-cat span {
  display: block;
  margin: 0 5px 5px 0;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  background: #ababb8;
}
@media screen and (max-width: 767px) {
  .top-news-list-cat span {
    font-size: 11px;
  }
}
.top-news-list-cat span:last-child {
  margin-right: 0;
}
.top-news-list-ttl {
  margin: 5px 0 0;
  color: #00a7b5;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .top-news-list-ttl {
    font-size: inherit;
  }
}
.top-news-list-txt {
  color: #000000;
}
@media screen and (max-width: 767px) {
  .top-news-list-txt {
    font-size: 11px;
  }
}

.top-blog-tab {
  display: flex;
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .top-blog-tab {
    font-size: 10px;
  }
}
.top-blog-tab-item {
  flex: 1;
}
.top-blog-tab-item + .top-blog-tab-item {
  margin-left: 2px;
}
@media screen and (max-width: 767px) {
  .top-blog-tab-item + .top-blog-tab-item {
    margin-left: 1px;
  }
}
.top-blog-tab-item a {
  display: block;
  padding: 5px 10px;
  color: #00a7b5;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  text-align: center;
  text-decoration: none;
  background: #f0eeec;
}
@media screen and (max-width: 767px) {
  .top-blog-tab-item a {
    padding: 5px 0;
  }
}
@media screen and (min-width: 768px) {
  .top-blog-tab-item a:hover {
    color: #fff;
    /* background: $primary; */
    background: #00a7b5;
  }
}
.top-blog-tab-item.is-active a {
  color: #fff;
  /* background: $primary; */
  background: #00a7b5;
}
.top-blog-tab-contents {
  display: none;
}

.top-feature {
  padding: 110px 0 300px;
  /* background: $primary; */
  background-image: url(../img/common/bg-gra-top.png);
}
@media screen and (max-width: 767px) {
  .top-feature {
    overflow: hidden;
    padding: 60px 0 120px;
  }
}
.top-feature .top-ttl {
  color: #000;
}
.top-feature-list {
  position: relative;
  padding: 96px 0 0;
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .top-feature-list {
    padding: 80px 0 0;
  }
}
.top-feature-list:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -100vw;
  bottom: -82px;
  left: 0;
  background: #fff;
}
@media screen and (max-width: 767px) {
  .top-feature-list:before {
    bottom: -30px;
  }
}
.top-feature-list-item {
  float: left;
  max-width: 335px;
  margin: 0 -70px 0 0;
  padding: 0 0 0 130px;
}
@media screen and (max-width: 767px) {
  .top-feature-list-item {
    max-width: 80vw;
    margin: 0 -3vw 0 0;
    padding: 0 0 0 10vw;
  }
}
.top-feature-list-item a {
  display: block;
  position: relative;
  color: inherit;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .top-feature-list-item a:hover .top-feature-list-img img {
    transform: scale(1.07);
  }
}
.top-feature-list-num {
  position: absolute;
  top: -45px;
  left: 30px;
  line-height: 1;
  /* color: $primary; */
  color: #00a7b5;
  font-size: 78px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  letter-spacing: 0.1em;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .top-feature-list-num {
    top: -40px;
    left: 40px;
    font-size: 70px;
  }
}
.top-feature-list-en {
  position: absolute;
  top: 50px;
  left: 0;
  line-height: 1;
  /* color: $primary; */
  color: #00a7b5;
  font-size: 17px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  font-weight: 500;
  writing-mode: vertical-rl;
  transform: rotate(180deg) translateX(50%);
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .top-feature-list-en {
    font-size: 15px;
  }
}
.top-feature-list-img {
  overflow: hidden;
  margin: 0;
}
.top-feature-list-img img {
  transition: 0.3s;
}
.top-feature-list-inner {
  position: relative;
  margin: -124px -25px 0 40px;
  padding: 40px;
  background: #fff;
}
@media screen and (max-width: 767px) {
  .top-feature-list-inner {
    margin: -100px -8vw 0 40px;
    padding: 30px;
  }
}
.top-feature-list-ttl {
  line-height: 1.5;
  margin: 0 0 10px;
  /* color: $primary; */
  color: #00a7b5;
  font-size: 17px;
}
@media screen and (max-width: 767px) {
  .top-feature-list-ttl {
    font-size: 16px;
  }
}
.top-feature-list-txt {
  margin: 0 0 10px;
}
.top-feature-list-more {
  /* color: $primary; */
  color: #00a7b5;
  font-size: 12px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .top-feature-list-more {
    font-size: 11px;
  }
}
.top-feature .slick-list:before, .top-feature .slick-list:after {
  content: "";
  display: block;
  clear: both;
}
.top-feature .slick-arrow {
  display: block;
  position: absolute;
  top: -86px;
  right: 0;
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 1px solid #fff;
  border-radius: 100%;
  text-indent: -100vw;
  background: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .top-feature .slick-arrow {
    top: -75px;
  }
}
.top-feature .slick-arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 17px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: translateY(-50%) rotate(45deg);
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .top-feature .slick-arrow:hover {
    background: #fff;
  }
  .top-feature .slick-arrow:hover:after {
    border-color: #4f2d7f;
  }
}
.top-feature .slick-prev {
  right: 58px;
}
.top-feature .slick-prev:after {
  right: 12px;
  transform: translateY(-50%) rotate(-135deg);
}

.top-office {
  position: relative;
  margin: 0 0 120px;
}
@media screen and (max-width: 767px) {
  .top-office {
    margin: 0 0 60px;
    padding: 50px 0 0;
  }
}
.top-office:before {
  content: "";
  display: block;
  position: absolute;
  top: -110px;
  right: -50px;
  left: -100vw;
  height: 110px;
  background: #fff;
}
@media screen and (max-width: 767px) {
  .top-office:before {
    content: none;
  }
}
.top-office-inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .top-office-inner {
    flex-direction: column-reverse;
  }
}
.top-office-list {
  list-style: none;
  width: 300px;
  margin: 0;
  padding: 0;
  border-top: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  .top-office-list {
    width: auto;
  }
}
.top-office-list-item {
  border-bottom: 1px solid #e6e6e6;
}
.top-office-list-item a {
  display: block;
  position: relative;
  padding: 15px 0;
  color: #00a7b5;
  text-decoration: none;
}
.top-office-list-item a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 10px;
  height: 10px;
  /* border-top: 1px solid $primary;
  border-right: 1px solid $primary; */
  border-top: 1px solid #00a7b5;
  border-right: 1px solid #00a7b5;
  transform: translateY(-50%) rotate(45deg);
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .top-office-list-item a:after {
    right: 3px;
  }
}
@media screen and (min-width: 768px) {
  .top-office-list-item a:hover:after {
    right: 10px;
  }
}
.top-office-img {
  width: 515px;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .top-office-img {
    width: auto;
    margin: 0 0 30px;
  }
}

.top-pamphlet {
  display: flex;
  justify-content: center;
  padding: 70px 20px;
  background: #c4c4cd;
}
@media screen and (max-width: 767px) {
  .top-pamphlet {
    display: block;
    padding: 50px 20px;
    text-align: center;
  }
}
.top-pamphlet-inner {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .top-pamphlet-inner {
    display: block;
  }
}
.top-pamphlet-img {
  max-width: 137px;
  margin: 0 35px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 767px) {
  .top-pamphlet-img {
    margin: 0 auto 20px;
  }
}
.top-pamphlet-ttl {
  margin: 0 0 15px;
  color: #4f2d7f;
}
.top-pamphlet-btn a {
  display: block;
  position: relative;
  width: 260px;
  padding: 13px 20px;
  border: 1px solid #4f2d7f;
  text-decoration: none;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .top-pamphlet-btn a {
    width: auto;
  }
}
.top-pamphlet-btn a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #4f2d7f;
  border-right: 1px solid #4f2d7f;
  transform: translateY(-50%) rotate(45deg);
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .top-pamphlet-btn a:hover {
    color: #fff;
    background: #4f2d7f;
  }
  .top-pamphlet-btn a:hover:after {
    border-color: #fff;
  }
}