@charset "utf-8";

@import "tools/mixins";
@import "tools/var";

body#top{
  position: relative;
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  @include mq(sp) {
    background-size: auto 205vh;
  }
}

.top-ttl {
  margin: 0 0 40px;
  /* color: $primary; */
  color: $secondary;
  font-weight: 500;
  @include mq(sp) {
    margin: 0 0 30px;
  }
  span {
    display: block;
    &[lang="en"] {
      font-size: 30px;
      font-family: $font-gt;
      font-weight: 500;
      letter-spacing: .1em;
      @include mq(sp) {
        font-size: 23px;
      }
    }
    &[lang="ja"] {
      margin: 5px 0 0;
      font-size: 14px;
      @include mq(sp) {
        font-size: 11px;
      }
    }
  }
}

.top-kv {
  position: relative;
  padding: 0 50px;
  /* background: $primary; */
  /* background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto; */
  @include mq(sp) {
    padding: 0 20px;
    background-size: cover;
  }
  &-inner {
    display: flex;
    align-items: center;
    position: relative;
    aspect-ratio: 1398.9705/679.569;
    /* height: calc(100vh - 122px); */
    padding: 0 6.67vw;
    background: url(/assets/img/top/kv-03.jpg) no-repeat 50% 50%;
    background-size: cover;
    @include mq(sp) {
      height: calc(100vh - 65px);
      aspect-ratio: inherit;
      padding: 0 7.2vw;
      background-position: top 0 right 30%;
      background-size: auto 100%;
    }
    img {
      width: 83vw;
      aspect-ratio: 1249.8457/118.6195;
      margin-inline: auto;
    }
  }
  &-scroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    color: #fff;
    font-family: $font-gt;
    letter-spacing: .1em;
    transform: translateX(-50%);
    @include mq(sp) {
      font-size: 10px;
    }
    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 130px;
      margin: 5px auto -65px;
      background: #fff;
      animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;
      z-index: 1000;
      @include mq(sp) {
        height: 58px;
        margin-bottom: -29px;
      }
    }
  }
  &-txt {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: calc(100vh - 122px);
    color: #fff;
    font-size: 10px;
    font-family: $font-gt;
    letter-spacing: .1em;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    @include mq(sp) {
      width: 20px;
      height: calc(100vh - 65px);
    }
  }
}

@keyframes sdl {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }

  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }

  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }

  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

.top-intro {
  padding: 120px 20px 200px;
  color: #fff;
  text-align: center;
  /* background: $primary; */
  /* background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto; */
  @include mq(sp) {
    padding: 70px 20px 100px;
  }
  &-ttl {
    margin: 0 0 50px;
    color: $secondary;
    font-size: 30px;
    font-weight: normal;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  &-txt {
    color: #000;
    font-size: 14px;
    @include mq(sp) {
      font-size: inherit;
      text-align: left;
    }
  }
}

.top-slide {
  position: relative;
  line-height: 1;
  margin: -75px 0 0;
  @include mq(sp) {
    margin: -60px 0 40px;
  }
  &-inner {
    width: 940px;
    overflow: hidden;
    transform: translateX(126px);
    @include mq(sp) {
      width: calc(100vw - 20px);
      transform: none;
    }
    .slick-arrow {
      display: block;
      position: absolute;
      bottom: 136px;
      overflow: hidden;
      width: 12px;
      height: 12px;
      margin: 0;
      padding: 0;
      border: none;
      border-top: 1px solid #656565;
      border-right: 1px solid #656565;
      border-radius: 0;
      text-indent: -100vw;
      background: none;
      cursor: pointer;
      z-index: 2000;
      @include mq(sp) {
        width: 10px;
        height: 10px;
        bottom: 70px;
      }
      &.slick-prev {
        right: 148px;
        transform: rotate(-135deg);
        @include mq(sp) {
          right: 35vw;
        }
      }
      &.slick-next {
        right: 128px;
        transform: rotate(45deg);
        @include mq(sp) {
          right: 30vw;
        }
      }
    }
    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      list-style: none;
      height: 95px;
      margin: 0;
      padding: 0 120px 0 0;
      background: #fff;
      transform: translate(-126px, -100%);
      z-index: 1000;
      @include mq(sp) {
        justify-content: flex-start;
        width: 60vw;
        height: 50px;
        padding: 0 0 0 25px;
        transform: translate(0, -100%);
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: -50px;
        bottom: 0;
        width: 50px;
        background: #fff;
        @include mq(sp) {
          content: none;
        }
      }
      li {
        button {
          display: block;
          width: 50px;
          height: 2px;
          overflow: hidden;
          margin: 0;
          padding: 0;
          border: none;
          text-indent: -100vw;
          background: #c2c2c2;
          @include mq(sp) {
            width: 20px;
          }
        }
        &.slick-active {
          button {
            background: #656565;
          }
        }
      }
    }
    .slick-num {
      position: absolute;
      bottom: 135px;
      left: 360px;
      font-family: $font-gt;
      z-index: 1000;
      @include mq(sp) {
        bottom: 70px;
        left: 0;
      }
    }
    .slick-all-count {
      position: absolute;
      right: 210px;
      bottom: 135px;
      font-family: $font-gt;
      z-index: 1000;
      @include mq(sp) {
        right: auto;
        bottom: 70px;
        left: 155px;
      }
    }
  }
}

.top-career{
  width: 100vw;
  margin: 0 calc(-50vw + 50%) 200px;
  padding: 30px 0;
  background-image: url(../img/top/career-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    margin: 0 calc(-50vw + 50%) 100px;
  }
  &__inner{
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 20px;
    @include mq(sp) {
      width: calc(100% - 20px - 20px);
    }
  }
  &__ul{
    display: flex;
    gap: 8px;
    list-style: none;
    margin: 0;
    padding: 0;
    transform: translateY(20%);
    @include mq(sp) {
      flex-direction: column;
      align-items: center;
      transform: none;
    }
  }
  &__li{
    width: calc((100% - 8px) / 2);
    padding: min(60px, 4vw) min(80px, 5.3vw);
    background-color: $secondary;
    color: #fff;
    @include mq(sp) {
      width: calc(100% - 20px - 20px);
      margin-inline: auto;
      padding: 10px 20px 20px;
    }
  }
  &__title{
    &--main{
      font-size: 36px;
      font-weight: 400;
      letter-spacing: .02em;
      @include mq(sp) {
        font-size: 28px;
      }
    }
    &--en{
      display: block;
      font-size: 14px;
      font-weight: 400;
      @include mq(sp) {
        font-size: 12px;
      }
    }
  }
  &__desc{
    font-size: 13px;
  }
  &__link{
    width: 50px;
    aspect-ratio: 1;
    opacity: 1;
    transition: opacity 0.3s;
    & img{
      width: 50px;
      aspect-ratio: 1;
    }
    &:hover{
      opacity: .7;
    }
  }




}

.top-news {
  position: relative;
  margin: 0 0 160px;
  @include mq(sp) {
    margin: 0 0 80px;
  }
  &-btn {
    position: absolute;
    top: 15px;
    right: 0;
    margin: 0;
    & a{
      color: $secondary;
    }
  }
  &-list {
    height: 450px;
    overflow: auto;
    padding: 0 30px 0 0;
    @include mq(sp) {
      padding: 0 20px 0 0;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #515151;
    }
    &-item {
      margin: 0 0 -1px;
      border-top: 1px solid $line;
      border-bottom: 1px solid $line;
      a {
        display: block;
        padding: 20px 0;
        text-decoration: none;
        &:hover {
          @include mq(pc) {
            .top-news-list-ttl {
              text-decoration: underline;
            }
          }
        }
      }
      & > span {
        display: block;
        padding: 20px 0;
      }
    }
    &-data {
      display: flex;
      line-height: 1;
      color: $secondary;
      font-family: $font-gt;
    }
    &-date {
      padding: 5px 0 0;
      font-size: 12px;
      @include mq(sp) {
        font-size: 10px;
      }
    }
    &-cat {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 0 20px;
      span {
        display: block;
        margin: 0 5px 5px 0;
        padding: 5px 10px;
        color: #fff;
        font-size: 12px;
        background: #ababb8;
        @include mq(sp) {
          font-size: 11px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-ttl {
      margin: 5px 0 0;
      color: $secondary;
      font-size: 14px;
      @include mq(sp) {
        font-size: inherit;
      }
    }
    &-txt {
      color: $default;
      @include mq(sp) {
        font-size: 11px;
      }
    }
  }
}

.top-blog {
  &-tab {
    display: flex;
    list-style: none;
    margin: 0 0 25px;
    padding: 0;
    @include mq(sp) {
      font-size: 10px;
    }
    &-item {
      flex: 1;
      & + & {
        margin-left: 2px;
        @include mq(sp) {
          margin-left: 1px;
        }
      }
      a {
        display: block;
        padding: 5px 10px;
        color: $secondary;
        font-family: $font-gt;
        text-align: center;
        text-decoration: none;
        background: #f0eeec;
        @include mq(sp) {
          padding: 5px 0;
        }
        &:hover {
          @include mq(pc) {
            color: #fff;
            /* background: $primary; */
            background: $secondary;
          }
        }
      }
      &.is-active {
        a {
          color: #fff;
          /* background: $primary; */
          background: $secondary;
        }
      }
    }
    &-contents {
      display: none;
    }
  }
}

.top-feature {
  padding: 110px 0 300px;
  /* background: $primary; */
  background-image: url(../img/common/bg-gra-top.png);
  @include mq(sp) {
    overflow: hidden;
    padding: 60px 0 120px;
  }
  .top-ttl {
    color: #000;
  }
  &-list {
    position: relative;
    padding: 96px 0 0;
    z-index: 0;
    @include mq(sp) {
      padding: 80px 0 0;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -100vw;
      bottom: -82px;
      left: 0;
      background: #fff;
      @include mq(sp) {
        bottom: -30px;
      }
    }
    &-item {
      float: left;
      max-width: 335px;
      margin: 0 -70px 0 0;
      padding: 0 0 0 130px;
      @include mq(sp) {
        max-width: 80vw;
        margin: 0 -3vw 0 0;
        padding: 0 0 0 10vw;
      }
      a {
        display: block;
        position: relative;
        color: inherit;
        text-decoration: none;
        &:hover {
          @include mq(pc) {
            .top-feature-list-img img {
              transform: scale(1.07);
            }
          }
        }
      }
    }
    &-num {
      position: absolute;
      top: -45px;
      left: 30px;
      line-height: 1;
      /* color: $primary; */
      color: $secondary;
      font-size: 78px;
      font-family: $font-gt;
      letter-spacing: .1em;
      z-index: 10;
      @include mq(sp) {
        top: -40px;
        left: 40px;
        font-size: 70px;
      }
    }
    &-en {
      position: absolute;
      top: 50px;
      left: 0;
      line-height: 1;
      /* color: $primary; */
      color: $secondary;
      font-size: 17px;
      font-family: $font-gt;
      font-weight: 500;
      writing-mode: vertical-rl;
      transform: rotate(180deg) translateX(50%);
      z-index: 10;
      @include mq(sp) {
        font-size: 15px;
      }
    }
    &-img {
      overflow: hidden;
      margin: 0;
      img {
        transition: .3s;
      }
    }
    &-inner {
      position: relative;
      margin: -124px -25px 0 40px;
      padding: 40px;
      background: #fff;
      @include mq(sp) {
        margin: -100px -8vw 0 40px;
        padding: 30px;
      }
    }
    &-ttl {
      line-height: 1.5;
      margin: 0 0 10px;
      /* color: $primary; */
      color: $secondary;
      font-size: 17px;
      @include mq(sp) {
        font-size: 16px;
      }
    }
    &-txt {
      margin: 0 0 10px;
    }
    &-more {
      /* color: $primary; */
      color: $secondary;
      font-size: 12px;
      font-family: $font-gt;
      text-decoration: underline;
      @include mq(sp) {
        font-size: 11px;
      }
    }
  }
  .slick-list {
    &:before,
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .slick-arrow {
    display: block;
    position: absolute;
    top: -86px;
    right: 0;
    width: 42px;
    height: 42px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 1px solid #fff;
    border-radius: 100%;
    text-indent: -100vw;
    background: none;
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
    @include mq(sp) {
      top: -75px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 17px;
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: translateY(-50%) rotate(45deg);
      transition: .3s;
    }
    &:hover {
      @include mq(pc) {
        background: #fff;
        &:after {
          border-color: $primary;
        }
      }
    }
  }
  .slick-prev {
    right: 58px;
    &:after {
      right: 12px;
      transform: translateY(-50%) rotate(-135deg);
    }
  }
}

.top-office {
  position: relative;
  margin: 0 0 120px;
  @include mq(sp) {
    margin: 0 0 60px;
    padding: 50px 0 0;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -110px;
    right: -50px;
    left: -100vw;
    height: 110px;
    background: #fff;
    @include mq(sp) {
      content: none;
    }
  }
  &-inner {
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      flex-direction: column-reverse;
    }
  }
  &-list {
    list-style: none;
    width: 300px;
    margin: 0;
    padding: 0;
    border-top: 1px solid $line;
    @include mq(sp) {
      width: auto;
    }
    &-item {
      border-bottom: 1px solid $line;
      a {
        display: block;
        position: relative;
        padding: 15px 0;
        color: $secondary;
        text-decoration: none;
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 20px;
          width: 10px;
          height: 10px;
          /* border-top: 1px solid $primary;
          border-right: 1px solid $primary; */
          border-top: 1px solid $secondary;
          border-right: 1px solid $secondary;
          transform: translateY(-50%) rotate(45deg);
          transition: .3s;
          @include mq(sp) {
            right: 3px;
          }
        }
        &:hover {
          @include mq(pc) {
            &:after {
              right: 10px;
            }
          }
        }
      }
    }
  }
  &-img {
    width: 515px;
    margin: 0;
    @include mq(sp) {
      width: auto;
      margin: 0 0 30px;
    }
  }
}

.top-pamphlet {
  display: flex;
  justify-content: center;
  padding: 70px 20px;
  background: #c4c4cd;
  @include mq(sp) {
    display: block;
    padding: 50px 20px;
    text-align: center;
  }
  &-inner {
    display: flex;
    align-items: center;
    @include mq(sp) {
      display: block;
    }
  }
  &-img {
    max-width: 137px;
    margin: 0 35px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    @include mq(sp) {
      margin: 0 auto 20px;
    }
  }
  &-ttl {
    margin: 0 0 15px;
    color: $primary;
  }
  &-btn {
    a {
      display: block;
      position: relative;
      width: 260px;
      padding: 13px 20px;
      border: 1px solid $primary;
      text-decoration: none;
      box-sizing: border-box;
      @include mq(sp) {
        width: auto;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        width: 8px;
        height: 8px;
        border-top: 1px solid $primary;
        border-right: 1px solid $primary;
        transform: translateY(-50%) rotate(45deg);
        transition: .3s;
      }
      &:hover {
        @include mq(pc) {
          color: #fff;
          background: $primary;
          &:after {
            border-color: #fff;
          }
        }
      }
    }
  }
}
