$sp: 767px;
$pc: 768px;
$lg-pc: 1200px;
$xs-sp: 375px;
@mixin mq($media) {
  @if $media == sp {
    @media screen and (max-width: #{$sp}) {
      @content;
    }
  }
  @else if $media == pc {
    @media screen and (min-width: #{$pc}) {
      @content;
    }
  }
  @else if $media == lg-pc {
    @media screen and (max-width: #{$lg-pc}) {
      @content;
    }
  }
  @else if $media == xs-sp {
    @media screen and (max-width: #{$xs-sp}) {
      @content;
    }
  }
}

@function _get_vw($size, $viewport: 375) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin font-vw($font_size: 10, $viewsize: 375) {
  font-size: _get_vw($font_size, $viewsize);
}
